import axios from 'axios';

const axiosBasic = axios.create({
    baseURL: 'http://rz2sbss.crusoe.one',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'webapp'
    },
});

const axiosForm = axios.create({
    baseURL: 'http://rz2sbss.crusoe.one',
    headers: {
        'Accept': 'application/json, text/javascript, */*; q=0.01',
        'Content-Type': 'multipart/form-data',
        'X-Requested-With':'webapp'
    }
});

export  {axiosBasic, axiosForm}
