<template>
    <li :class="{'is-active':checkActive, 'is-open':open}">
        <!--check for 3rd level-->
        <template v-if="subnav && subnav.length">
            <span class="sublink" @click="openLevel3">{{ item.title }} <svg class="icon"><use xlink:href="#angle-right"></use></svg></span>
            <div class="level-3" @mouseleave="open = false">
                <ul>
                    <li class="nav-back" @click="openLevel2"><svg class="icon"><use xlink:href="#angle-left"></use></svg> Zurück</li>
                    <li class="title"><span>{{ item.title }}</span></li>
                    <li v-for="(child, index) in subnav" :key="index">
                        <a :href="getLink(child)" v-if="child.is_external">{{ child.title }}</a>
                        <router-link :to="getLink(child)" v-else @click.native="resetNav">{{ child.title }}</router-link>
                    </li>
                </ul>
            </div>
        </template>
        <a :href="getLink(item)" v-else-if="item.is_external">{{ item.title }}</a>
        <router-link :to="getLink(item)" v-else @click.native="resetNav">{{ item.title }}</router-link>
    </li>
</template>

<script>
    export default {
        props:['item', 'name', 'subnav', 'icon'],
        computed:{
            checkActive(){
                let nav = JSON.stringify(this.item);

                nav = nav.replaceAll(',"children":','},');
                nav = nav.replaceAll('[','');
                nav = nav.replaceAll(']','');
                nav = nav.replaceAll('}}','}');
                nav = nav.replaceAll('{{','{');
                nav = nav.replaceAll('},}','}');

                nav = JSON.parse("["+nav+"]");

                let currentItem = nav.filter(item => item.alias === this.$route.path)

                // save current item in store
                if(currentItem.length) {
                    this.$store.commit('content/SET_NAVITEMTWO', this.item);
                }

                return currentItem.length;
            }
        },
        data() {
            return {
                open:false
            }
        },
        watch: {
            'navLevel'(val){
                if(val !== 3)
                    this.open = false;
            }
        },
        methods: {
            getLink(item){
                if(item.is_external)
                    return item.uri;

                if(item.alias && item.alias !== "/")
                    return item.alias;

                return item.internal_path;
            },
            openLevel3(){
                this.$store.commit('config/CHANGE_NAV_LEVEL', 3);
                this.open = true;
            },
            openLevel2(){
                this.$store.commit('config/CHANGE_NAV_LEVEL', 2);
                this.open = false;
            },
            resetNav(){
                this.open = false;
                this.$store.commit('config/SWITCH_NAV_SIZE', false);
                this.$emit('reset-nav');
            }
        }
    };
</script>
